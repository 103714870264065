import { gql } from '@apollo/client'

// REMOTE
export const LOGIN_MUTATION = gql`
  mutation login($userCredentials: userCredentials!) {
    login(input: $userCredentials)
  }
`

export const UPDATE_PART_MUTATION = gql`
  mutation savePart($input: inputPartData!) {
    savePart(input: $input)
  }
`
