import React, { forwardRef } from 'react'

export default forwardRef(({ value = '', defaultValue = '', id = Math.round((Math.random() * 100)), label = 'Label', type = 'text', placeholder = 'placeholder', onChange = () => {}, disable }, ref) => {
  const disabled = disable ? 'pointer-events-none opacity-25' : ''
  return <div>
    <label htmlFor={id} className='block text-sm font-medium text-gray-700'>{label}</label>
    <div className='mt-1'>
      <input ref={ref} defaultValue={defaultValue} value={value} onChange={e => onChange(e.target.value)} type={type} name={id} id={id} className={`shadow-sm ${disabled} focus:ring-indigo-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md`} placeholder={placeholder} />
    </div>
  </div>
})
