import { gql } from '@apollo/client'

export const User = {
  fragments: {
    UserBasicData: gql`
      fragment UserBasicData on User {
        name  
        username
        email
      }
    ` },
}

export const Part = {
  fragments: {
    PartData: gql`
      fragment PartData on Part {
        id
        partNumber
        location
        catalog
        quantityOnHand
        description
        matches
        duplicate
        link
        vendors
      }
    ` },
}
