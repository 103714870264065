import React from 'react'

const links = [
  {
    id: 'part-data',
    label: 'Part number',
    icon: () => <svg className='mr-4 h-6 w-6 text-gray-300' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z' />
    </svg>,
  },
  {
    id: 'part-location',
    label: 'Part location',
    icon: () => <svg className='mr-4 h-6 w-6 text-gray-300' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01' />
    </svg>,
  },
]

export default ({ linkCallback, logoutCallback, user = {}, showMobileMenu = false, setShowMobileMenu = () => {} }) => {
  const changeTab = (e, index) => {
    e.preventDefault()
    setShowMobileMenu(false)
    linkCallback(index)
  }
  return <>
    { showMobileMenu && <div className='bg-gray-900 z-10 absolute top-14 left-0 -mr-12 pt-2'>
      <div className='flex-shrink-0 flex items-center p-4'>
        <span onClick={logoutCallback} className='text-xs text-center px-4 py-1 font-medium bg-gray-600 rounded-lg text-gray-300 group-hover:text-gray-200 font-bold'>
          Logout
        </span>
      </div>
      <div className='mt-5 flex-1 h-36 overflow-y-auto'>
        <nav className='px-2 space-y-1'>
          {links.map((link, idx) => (
            <a onClick={e => changeTab(e, idx)} key={link.id} href='#' className='group flex items-center px-2 py-4 text-sm font-medium text-white rounded-md bg-gray-900'>
              {link.icon()}
              {link.label}
            </a>
          ))}
        </nav>
      </div>
    </div>}
    <div className='hidden md:flex md:flex-shrink-0'>
      <div className='flex flex-col w-64'>
        <div className='flex flex-col h-0 flex-1 bg-gray-400'>
          <div className='flex-1 flex flex-col pt-5 overflow-y-auto'>
            <div className='flex items-center flex-shrink-0 px-4'>
              <img className='h-8 w-auto' src='https://d33wubrfki0l68.cloudfront.net/f9949b49beecc8e1f1e66303dffa5687307f3185/f71aa/images/resistell-logo.png' alt='Workflow' />
            </div>
            <nav className='mt-5 flex-1 bg-gray-800'>
              {
                links.map((link, idx) => <a onClick={e => changeTab(e, idx)} key={link.id} href='#' className='group flex items-center px-2 py-4 text-sm font-medium text-white rounded-md bg-gray-900'>
                  {link.icon()}
                  {link.label}
                </a>)
              }
            </nav>
          </div>
          <div className='flex-shrink-0 flex bg-gray-800 p-0'>
            <a href='#' className='flex-shrink-0 w-full group block'>
              <div className=' border-solid border-gray-100 border-t mx-4 opacity-25' />
              <div className='flex items-center p-4'>
                <div className='mx-3 flex  justify-between w-full items-center'>
                  <span className='text-sm font-medium text-white truncate flex items-center justify-items-start'>
                    {user.username || user.name}
                  </span>
                  <span onClick={logoutCallback} className='text-xs text-center px-4 py-1 font-medium bg-gray-600 rounded-lg text-gray-300 group-hover:text-gray-200 font-bold'>
                    Logout
                  </span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
}
