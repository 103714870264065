import * as React from 'react'
import { Button } from '../ui'

const digitsCount = (n) => {
  let count = 0
  if (n >= 1) ++count

  while (n / 10 >= 1) {
    n /= 10
    ++count
  }

  return count
}

const setIntialUnit = (qty) => {
  const digits = digitsCount(qty)
  const pow = digits < 3
    ? 0
    : digits < 4
      ? 1
      : digits - 2
  return Math.pow(10, pow)
}

export default ({ data, updateCallback = () => {}, loading }) => {
  const [unit, setUnit] = React.useState(data.quantityOnHand ? setIntialUnit(data.quantityOnHand) : 1)
  if (!data) return null
  const [count, setCount] = React.useState(data.quantityOnHand || 0)
  const [location, setLocation] = React.useState(data.location)
  return <div className='flex items-between justify-center w-full flex-wrap'>
    <div className='flex justify-between flex-col w-full items-center flex-wrap py-8 bg-gray-100 rounded-lg'>
      <div className='pl-4 w-full mb-4'>
        <label htmlFor='location' className='block text-sm font-medium text-gray-700'>Location</label>
        <div className='mt-1 relative rounded-md shadow-sm'>
          <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
            <svg className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
              <path fillRule='evenodd' d='M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z' clipRule='evenodd' />
            </svg>
          </div>
          <input type='text' name='location' value={location} onChange={(e) => setLocation(e.target.value)} id='location' className='focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md' placeholder='Add location' />
        </div>
      </div>
      <div className='pl-4 w-full mb-4'>
        <div className='w-full flex items-center'>
          <label htmlFor='quantity' className='block text-sm font-medium text-gray-700'>Quantity on Hand: </label>
          <span className='pl-2'>[{count}]</span>
        </div>
        <div className='w-32'>
          <span id='quantity' name='quantity' className='relative z-0 inline-flex shadow-sm rounded-md'>
            <button onClick={() => count > 0 && setCount(count - unit)} type='button' className='relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'>
              <span className='sr-only'>Previous</span>
              <svg className='h-5 w-5' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z' />
              </svg>
            </button>
            <div className='w-1/6 inline-block relative overflow-hidden'>
              <input type='number' onChange={(e) => setUnit(parseInt(e.target.value, 10))} value={unit} className='-ml-px relative inline-flex justify-center items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500' />
            </div>
            <button onClick={() => setCount(count + unit)} type='button' className='-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'>
              <span className='sr-only'>Add</span>
              <svg className='h-5 w-5' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
                <path fillRule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z' clipRule='evenodd' />
              </svg>
            </button>
          </span>
        </div>
      </div>
    </div>
    <div className='w-full flex justify-center mt-12'>
      <div className='w-24'>
        <Button onClick={() => !loading && updateCallback({ location, quantityOnHand: count })} buttonText={loading ? '...Updating' : 'Update'} />
      </div>
    </div>
  </div>
}
