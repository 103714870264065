import * as React from 'react'
import { Authenticate } from './general'
import { PartItem } from './part'

export default ({ match }) => {
  return <Authenticate>
    <div className='py-6'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <h1 className='text-2xl font-semibold text-gray-900'>BACK</h1>
      </div>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
        <div className='py-4'>
          {/* <div className='border-4 border-dashed border-gray-200 rounded-lg h-96'> */}
          <div className='h-96'>
            PART | SINGLE ITEM
          </div>
        </div>
      </div>
    </div>
  </Authenticate>
}
