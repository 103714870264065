import * as React from 'react'
import { useToasts } from 'react-toast-notifications'
import { useMutation, useQuery } from '@apollo/client'
import { Input, Button } from '../ui'
import { Sideout } from '../layout'
import { PART_DATA_QUERY, GET_PARTS_QUERY, UPDATE_PART_MUTATION } from '../../api'
import PartData from './_part-data'
import PartList from './_part-list'
import PartEdit from './_part-edit'

export default () => {
  const inputRef = React.useRef(null)
  const { addToast } = useToasts()
  const [partNumber, setPartNumber] = React.useState()
  const [partId, setPartId] = React.useState()
  const [partData, setPartData] = React.useState()
  const [data, setData] = React.useState([])
  const [fetchData, setFetchData] = React.useState()
  const [showSideOut, setShowSideout] = React.useState()
  // const { data: { getPartDataById: partItem } = {}, loading: partLoading } = useQuery(PART_DATA_QUERY, {
  //   variables: {
  //     id: partId,
  //   },
  //   skip: !partId,
  // })
  const { data: { getParts: partListData } = {}, loading: searchLoading } = useQuery(GET_PARTS_QUERY, {
    variables: {
      params: {
        partNumber,
      },
    },
    skip: !fetchData || partId || !partNumber,
  })
  const [save, { loading: updating, error: updateError }] = useMutation(UPDATE_PART_MUTATION)

  const updatePart = ({ location, quantityOnHand }) => {
    save({
      variables: {
        input: {
          partNumber: partData.partNumber,
          catalogId: partData.catalog.id,
          location,
          quantityOnHand,
        },
      },
    }).then(() => {
      setPartData({
        ...partData,
        location,
        quantityOnHand,
      })
      setData(data.map(part => {
        if (part.partNumber === partNumber && part.catalog.id === partData.catalog.id) {
          return {
            ...part,
            location,
            quantityOnHand,
          }
        }
        return part
      }))
      addToast('Part successfully updated', { appearance: 'success', autoDismiss: true })
      setShowSideout(false)
    })
      .catch(e => addToast('There was an error while updating!', { appearance: 'error', autoDismiss: true }))
  }
  const readPartNumber = (e) => {
    e.preventDefault()
    if (fetchData && !searchLoading) {
      inputRef.current.focus()
      setPartNumber('')
    }
    setFetchData(!fetchData)
    setPartData(null)
    setPartId(null)
  }
  const editPartNumber = (e) => {
    e.preventDefault()
    if (fetchData) setShowSideout(!showSideOut)
  }

  const itemClickCallback = (id) => {
    setPartId(id)
  }

  React.useEffect(() => {
    // if (partItem) setPartData(partItem)
    if (partId) setPartData(data.filter(item => item.id === partId)[0])
  }, [partId])

  React.useEffect(() => {
    if (partListData) setData(partListData)
  }, [partListData, partId])
  return <>
    <div className='my-4 mx-12 w-full flex items-center  justify-between mb-8'>
      <div className='w-8/12'>
        <Input ref={inputRef} disable={fetchData} value={partNumber} onChange={setPartNumber} placeholder='Click to scan / or keyboard input text field' label='Part number' />
      </div>
      <div className='w-4/12 ml-4 h-8'>
        { partNumber && <Button onClick={readPartNumber} loading={searchLoading} buttonText={fetchData ? searchLoading ? 'Read' : 'Read another' : 'Read'} />}
      </div>
    </div>
    { fetchData && <PartList partData={partData} editPartNumber={editPartNumber} data={data} partNumber={partNumber} loading={searchLoading} itemClickCallback={itemClickCallback} activeId={partId} /> }
    {
      showSideOut && <Sideout closeCallback={() => setShowSideout(!showSideOut)} title={`Edit Part n. ${partData.partNumber}`}>
        <PartEdit data={partData} updateCallback={updatePart} loading={updating} />
      </Sideout>
    }
  </>
}
