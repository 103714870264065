import * as React from 'react'
import { Transition } from '@headlessui/react'
import useOnClickOutside from 'use-onclickoutside'

export default ({ children, closeCallback = () => {}, title }) => {
  const ref = React.useRef(null)
  const [show, setShow] = React.useState(true)
  const closePanel = () => {
    setShow(false)
    setTimeout(() => closeCallback(), 500)
  }
  useOnClickOutside(ref, closePanel)

  return <div className='fixed inset-0 overflow-hidden'>
    <div className='absolute inset-0 overflow-hidden'>
      <Transition
        show={show}
        appear
        enter='ease-in-out duration-500'
        enterFrom='opacity-0'
        enterTo='opacity-1000'
        leave='ease-in-out duration-500'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <div className='absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity' aria-hidden='true' />
      </Transition>
      <Transition
        show={show}
        appear
        enter='transform transition ease-in-out duration-500 sm:duration-700 h-screen'
        enterFrom='translate-x-full'
        enterTo='translate-x-0'
        leave='transform transition ease-in-out duration-500 sm:duration-700 h-screen'
        leaveFrom='translate-x-0'
        leaveTo='translate-x-full'
      >
        <section ref={ref} className='absolute inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16' aria-labelledby='slide-over-heading'>
          <div className='w-screen max-w-2xl'>
            <div className='h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll'>
              <div className='px-4 sm:px-6'>
                <div className='flex items-start justify-between'>
                  <h2 id='slide-over-heading' className='text-lg font-medium text-gray-900'>
                    {title}
                  </h2>
                  <div className='ml-3 h-7 flex items-center'>
                    <button onClick={closePanel} className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                      <span className='sr-only'>Close panel</span>
                      <svg className='h-6 w-6' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
                        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12' />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className='mt-6 relative flex-1 px-4 sm:px-6'>
                {children}
              </div>
            </div>
          </div>
        </section>
      </Transition>
    </div>
  </div>
}
