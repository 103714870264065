import * as React from 'react'
import { Layout } from './layout'
import { PartNumber, PartLocation } from './part'
// import { PartListData } from '../containers'
import { useAuth } from './general'

const tabs = {
  0: {
    title: 'PART DATA',
    component: () => <PartNumber />,
  },
  1: {
    title: 'PART LOCATION',
    component: () => <PartLocation />,
  },
}

export default ({ match }) => {
  const { user = {}, logout } = useAuth() || {}
  const [tabIndex, setTabIndex] = React.useState(0)
  return <Layout match={match} user={user} linkCallback={setTabIndex} logoutCallback={logout}>
    <div className='py-6'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <h1 className='text-2xl font-semibold text-gray-900'>{tabs[tabIndex].title}</h1>
      </div>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
        <div className='py-4'>
          {/* <div className='border-4 border-dashed border-gray-200 rounded-lg h-96'> */}
          <div className='h-96'>
            {tabs[tabIndex].component()}
          </div>
        </div>
      </div>
    </div>
  </Layout>
}
