import * as React from 'react'

const forbiddenKeyFields = ['catalogId', '__typename', 'id', 'vendors']

export default ({ data }) => {
  if (!data) return <p>No results</p>
  const transformedData = Object.entries(data).reduce((obj, [k, v]) => {
    return {
      ...obj,
      ...(k === 'catalog') && {
        catalogId: v.id,
        catalogName: v.name,
        catalogUser: v.user,
      },
      ...(k === 'vendors') && {
        ...Object.entries(v).filter(e => !!e[1]).reduce((o, entry) => ({ ...o, [entry[0]]: <a href={entry[1]} target='_blank' className='underline text-blue-500'>{entry[1]}</a> }), {}),
      },
      ...(k !== 'catalog') && {
        [k]: v,
      },
    }
  }, {})

  return <div className='bg-white shadow overflow-hidden sm:rounded-lg'>
    <div className='px-4 py-5 sm:px-6'>
      <h3 className='text-lg leading-6 font-medium text-gray-900'>
        {data.partNumber}
      </h3>
      <p className='mt-1 max-w-2xl text-sm text-gray-500'>
        Data from OpenBOM
      </p>
    </div>
    <div className='border-t border-gray-200 px-4 py-5 sm:p-0'>
      {
        Object.entries(transformedData).filter(([k]) => !(forbiddenKeyFields.includes(k))).map(([key, value]) =>
          <dl className='sm:divide-y sm:divide-gray-200'>
            <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>
                {key}
              </dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {
                  key === 'link' ? <a href={value} target='_blank' className='underline text-blue-500'>Open <span className='font-bold'>{transformedData.catalogName}</span> on OpenBOM</a> : value}
              </dd>
            </div>
          </dl>)
      }
    </div>
  </div>
}
