import { gql } from '@apollo/client'
import {
  User,
  Part,
} from './_fragments'

// REMOTE
export const CONNECTION_QUERY = gql`
  query connection {
    connection
  }
`

export const AUTH_QUERY = gql`
  query authenticate {
    _checkAuth
  }
`

export const CURRENT_USER_QUERY = gql`
  query currentUser {
    currentUser {
      ...UserBasicData
    }
  }
  ${User.fragments.UserBasicData}
`

export const PART_DATA_QUERY = gql`
  query getPartDataById($id: String!) {
    getPartDataById(id: $id) {
      ...PartData
    }
  }
  ${Part.fragments.PartData}
`

export const GET_PARTS_QUERY = gql`
  query getParts($params: JSON) {
    getParts(params: $params) {
      ...PartData
    }
  }
  ${Part.fragments.PartData}
`
