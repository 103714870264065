import * as React from 'react'
import { Transition } from '@headlessui/react'
import { PartData, PartItem } from '../part'
import { Button } from '../ui'
import { Loading } from '../general'

export default ({ skipHighlighting, partNumber, data, updating, partData, editPartNumber, loading, activeId, itemClickCallback = () => {} }) => {
  if (loading) return <Loading />
  if ((!data && !loading) || (!data.length && !loading)) return 'No data'

  return <div className='bg-white shadow overflow-hidden sm:rounded-md'>
    <ul className='divide-y divide-gray-200'>
      {
        data.map(item => {
          if (partData && activeId === item.id) {
            return updating
              ? <p>Updating OpenBOM</p>
              : <div>
                <PartItem skipHighlighting={skipHighlighting} partNumber={partNumber} item={item} isActive={activeId === item.id} isDuplicate={item.duplicate} />
                <Transition
                  appear
                  show={partData && activeId === item.id}
                  enter='transform transition ease-in-out duration-500 sm:duration-100 h-screen'
                  enterFrom='-translate-x-full'
                  enterTo='translate-x-0'
                  leave='transform transition ease-in-out duration-500 sm:duration-200 h-screen'
                  leaveFrom='translate-x-0'
                  leaveTo='translate-x-full'
                >
                  <div className='container flex justify-center items-center flex-wrap mb-8'>
                    <div className='w-full my-6'>
                      <PartData data={partData} />
                    </div>
                    <Button onClick={editPartNumber} buttonText={'Edit'} />
                  </div>
                </Transition>
              </div>
          }
          return <li onClick={() => itemClickCallback(item.id)}>
            <PartItem skipHighlighting={skipHighlighting} partNumber={partNumber} item={item} isActive={activeId === item.id} isDuplicate={item.duplicate} />
          </li>
        }
        )
      }
    </ul>
  </div>
}
