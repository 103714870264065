import * as React from 'react'
import Sidebar from './_sidebar'

export default ({ linkCallback = () => {}, logoutCallback = () => {}, user = {}, children }) => {
  const [showMobileMenu, setShowMobileMenu] = React.useState(false)
  return <div className='h-screen flex overflow-hidden bg-gray-100'>
    <Sidebar showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu} linkCallback={linkCallback} logoutCallback={logoutCallback} user={user} />
    <div className='flex flex-col w-0 flex-1 overflow-hidden'>
      <div className='md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3'>
        <button onClick={() => setShowMobileMenu(!showMobileMenu)} className='-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
          <span className='sr-only'>Open sidebar</span>
          <svg className='h-6 w-6' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h16M4 18h16' />
          </svg>
        </button>
      </div>
      <main className='flex-1 relative z-0 overflow-y-auto focus:outline-none' tabIndex='0'>
        { children }
      </main>
    </div>
  </div>
}
