import * as React from 'react'

const HighlightPartNumber = ({ value = '', matches }) => {
  const delimiters = matches.map(match => value.substring(...match))
  // console.log(delimiters)
  let splits = []
  // splits = value.split(partNumber)
  // splits = splits.reduce((arr, split, index) => {
  //   if (index % 2) {
  //     return [
  //       ...arr,
  //       partNumber,
  //       split,
  //     ]
  //   }
  //   return [
  //     ...arr,
  //     split,
  //   ]
  // }, [])
  splits = delimiters.reduce((array, partNumber) => {
    let strings = value.split(partNumber)
    strings = strings.reduce((arr, split, index) => {
      if (index % 2) {
        return [
          ...arr,
          partNumber,
          split,
        ]
      }
      return [
        ...arr,
        split,
      ]
    }, [])
    return [
      ...array,
      ...strings,
    ]
  }, [])
  return splits.map(split => {
    if (delimiters.includes(split)) return <span className='inline-block bg-yellow-300'>{split}</span>
    return <span>{split}</span>
  })
}

export default ({ skipHighlighting = false, item, isActive, isDuplicate }) => {
  if (!item) return null
  const modifiedDate = new Date(item.catalog.modified).toLocaleString('en-UK', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' })
  return <a href='#' className={`block ${isActive ? 'bg-green-100' : isDuplicate ? 'bg-red-200' : 'hover:bg-gray-50'}`}>
    <div className='px-4 py-4 sm:px-6'>
      <div className='flex items-center justify-between'>
        <p className='text-sm font-medium text-indigo-600 truncate'>
          {isDuplicate ? '[Duplicate in catalog] >>> ' : ''}{!skipHighlighting ? <HighlightPartNumber value={item.partNumber} matches={item.matches} /> : <span className='bg-gray-200 py-1 px-2 rounded-md'>{item.partNumber}</span>} (by {item.catalog.user})
        </p>
        <div className='ml-2 flex-shrink-0 flex'>
          <p className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-200 ${item.quantityOnHand > 0 ? item.quantityOnHand > 2 ? 'text-green-800' : 'text-orange-400' : 'text-red-400'}`}>
            Qty: {item.quantityOnHand}
          </p>
        </div>
      </div>
      <div className='mt-2 sm:flex sm:justify-between'>
        <div className='sm:flex'>
          <p className='flex items-center text-sm text-gray-500'>
            <svg className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
              <path d='M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z' />
            </svg>
            {item.catalog && item.catalog.name}
          </p>
          <p className='mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6'>
            <svg className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
              <path fillRule='evenodd' d='M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z' clipRule='evenodd' />
            </svg>
            {item.location}
          </p>
        </div>
        <div className='mt-2 flex items-center text-sm text-gray-500 sm:mt-0'>
          <svg className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
            <path fillRule='evenodd' d='M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z' clipRule='evenodd' />
          </svg>
          <p>
            Last catalog update on
            <time dateTime={modifiedDate}> {modifiedDate}</time>
          </p>
        </div>
      </div>
    </div>
  </a>
}
